import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    DatePicker,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Divider,
    Row,
    Col,
    Form,
    Radio,
    Upload,
    message,
    List
} from 'antd';
import store from "../store/store.js";
import {InboxOutlined, LinkOutlined, ScanOutlined, UploadOutlined, DeleteOutlined} from '@ant-design/icons'
import moment from 'moment';

const {Option} = Select;
const {Dragger} = Upload;
const {TextArea} = Input;
export default class Add extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id,
                picfile: [],
            },
            visible: false,
            loading: false,
            loading2: false
        }
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }

    componentDidMount() {

    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, {[name]: dateString})
        this.setState({
            condition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        this.setState({
            condition: data
        })
        if (name == 'aid') {
            setTimeout(() => {
                that.searchtime()
            })
        }
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        this.setState({
            condition: data
        })
    }

    ontimeChange(name, date, dateString) {
        let that = this
        let value = dateString
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, {[name]: value})
        this.setState({
            condition: data
        })
        if (name == 'time') {
            setTimeout(() => {
                that.searchtime()
            })
        }
    }

    searchtime() {
        let that = this
        let arr = {}
        arr.aid = that.state.condition.aid
        arr.time = that.state.condition.time
        fetch('/Rizhi/searchtime', {
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status != 0) {
                    let condition = Object.assign({}, that.state.condition, {addtime: data.addtime})
                    this.setState({
                        condition
                    })
                } else {
                    // message.error(data.msg)
                }
            })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index}>{item.name}</Option>
            )
        }
    }

    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }

    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Rizhi/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg);
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }

    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            fetch('/Rizhi/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (this.state.condition.id) {
                        if (data.status == 1) {
                            data.data.status = Number(data.data.status);
                            that.setState({
                                condition: data.data,
                            })
                        } else {
                            message.error(data.msg)
                            that.mdchange()
                        }
                    }
                    that.setState({
                        aid_react: data.aid_react ? data.aid_react : []
                    })
                    console.log(data)
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    picfile: [],
                },
                loading: false
            })
            that.props.refresh()
        }
    }

    arrfile() {
        if (this.state.condition.picfile) {
            return this.state.condition.picfile.map((item, index) =>
                <Col span={24}><a href={item.url} target="_blank" key={index}>{item.name}</a><DeleteOutlined
                    onClick={this.delfile.bind(this, index)} className="delfile"/></Col>
            )
        }
    }

    delfile(index, e) {
        console.log(index, e)
        var picfile = this.state.condition.picfile
        picfile.splice(index, 1)
        let data = Object.assign({}, this.state.condition, {picfile: picfile})
        this.setState({
            condition: data,
        })
    }

    // 上传图片
    picChange(info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({loading2: true});
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var picfile = this.state.condition.picfile
            picfile = picfile.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, {picfile: picfile})
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };

    render() {
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' type="link" onClick={this.mdchange.bind(this)}>编辑</a> :
                    <Button type="primary" onClick={this.mdchange.bind(this)} className='but yellow'>添加日志</Button>}
                <Modal title="编辑日志" width={1000} visible={this.state.visible} onCancel={this.mdchange.bind(this)}
                       footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 8 }}
                        wrapperCol={{span: 24}}
                        autoComplete="off"
                        className='addview'

                    >
                        <Row gutter={[20, 0]} justify="space-between">
                            <Col className="gutter-row" span={8}>
                                <Form.Item
                                    label="添加人"
                                    // required={true}
                                >
                                    <Select style={{width: '100%'}} onChange={this.onSelChange.bind(this, 'aid')}
                                            placeholder="请选择"
                                            value={this.state.condition.aid ? this.state.condition.aid : null}>
                                        {this.arrsel(this.state.aid_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item
                                    label="日期"
                                    // required={true}
                                >
                                    <DatePicker style={{width: '100%'}} onChange={this.ontimeChange.bind(this, 'time')}
                                                value={this.state.condition.time ? moment(this.state.condition.time) : null}/>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item
                                    label="添加时间"
                                    // required={true}
                                >
                                    <DatePicker style={{width: '100%'}} format="YYYY-MM-DD HH:mm" showTime={{
                                        defaultValue: moment('00:00', 'HH:mm'),
                                    }} onChange={this.ontimeChange.bind(this, 'addtime')}
                                                value={this.state.condition.addtime ? moment(this.state.condition.addtime) : null}/>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="今日完成工作"
                                    required={true}
                                >
                                    <TextArea rows={10} placeholder="请输入"
                                              value={this.state.condition.jrwcgz ? this.state.condition.jrwcgz : null}
                                              onChange={this.onChange.bind(this, 'jrwcgz')}/>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="未完成工作"
                                    required={true}
                                >
                                    <TextArea rows={6} placeholder="请输入"
                                              value={this.state.condition.wwcgz ? this.state.condition.wwcgz : null}
                                              onChange={this.onChange.bind(this, 'wwcgz')}/>
                                </Form.Item>
                            </Col>
                            {/* <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="需协调工作"
                                >
                                    <TextArea rows={2} placeholder="请输入" value={this.state.condition.xxtgz ? this.state.condition.xxtgz : null} onChange={this.onChange.bind(this, 'xxtgz')} />
                                </Form.Item>
                            </Col> */}
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <TextArea rows={4} placeholder="请输入"
                                              value={this.state.condition.bz ? this.state.condition.bz : null}
                                              onChange={this.onChange.bind(this, 'bz')}/>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="图片"
                                >
                                    <Space align="start">
                                        <Upload
                                            showUploadList={false}
                                            action="/Common/uploadFile"
                                            onChange={this.picChange.bind(this)}
                                            multiple={true}
                                        >
                                            <Button icon={<UploadOutlined/>}>上传</Button>
                                        </Upload>
                                        <Row align="middle">
                                            {this.arrfile()}
                                        </Row>
                                    </Space>
                                </Form.Item>
                            </Col>
                            {/* {store.getState().data.info.type == 1 ?
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        label="状态"
                                    >
                                        <Radio.Group onChange={this.onChange.bind(this, 'status')} value={this.state.condition.status ? this.state.condition.status : []}>
                                            <Radio value={1}>已读</Radio>
                                            <Radio value={2}>未读</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                : null
                            } */}
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    wrapperCol={{offset: 11}}
                                >
                                    <Button type="primary" onClick={this.sub.bind(this)}
                                            loading={this.state.loading}>提交</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}
