import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    DatePicker,
    Space,
    Select,
    Input,
    Button,
    Table,
    Pagination,
    Row,
    Tag,
    Modal,
    Tooltip,
    Popconfirm,
    message
} from 'antd';
import Add from "./add";
import Add2 from "./add2";
import store from "../store/store.js";
import {PaperClipOutlined, LinkOutlined, SendOutlined} from '@ant-design/icons'
import moment from 'moment';

const {Option} = Select;
const {RangePicker} = DatePicker;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            user_react: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }

    componentDidMount() {
        this.getdata(0)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    onRangeChange(e, dates, dateStrings) {
        console.log(dates, dateStrings)
        let data = Object.assign({}, this.state.newcondition, {starttime: dateStrings[0], endtime: dateStrings[1]})
        this.setState({
            newcondition: data
        })
    }

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };
    getdata = (subtype = 0, e) => {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Rizhi/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                that.setState({
                    list: data.data,
                    user_react: data.user_react,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Rizhi/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    xbxdinfo(info, e) {
        console.log(info)
        Modal.info({
            icon: null,
            content: info,
            okText: '关闭'
        });
    }

    onchange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
    }

    download() {
        // type==1 下载选中部分
        let that = this
        that.setState({
            downloading: true
        })
        var arr = Object.assign({}, this.state.oldcondition, {})
        arr.download = 1
        fetch('/Rizhi/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }

                that.setState({
                    downloading: false
                })
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
            })
    }

    render() {
        const columns = [
            {
                title: '添加时间',
                dataIndex: 'time',
                key: 'time',
                width: 150,
            },
            {
                title: '添加人',
                dataIndex: 'aname',
                key: 'aname',
                width: 150,
            },
            {
                title: '今日完成工作',
                dataIndex: 'jrwcgz',
                key: 'jrwcgz',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '状态',
                dataIndex: 'zt',
                key: 'zt',
                width: 100,
                render: (row, record) => {
                    return (
                        <>
                            {record.status == 1 ? <Tag color="green">{row}</Tag> : null}
                            {record.status == 2 ? <Tag color="orange">{row}</Tag> : null}
                        </>
                    )
                },
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 100,
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                {store.getState().data.info.type == 1 ?
                                    <Add2 id={record.id} refresh={this.getdata.bind(0, this)}/>
                                    :
                                    <Add id={record.id} refresh={this.getdata.bind(0, this)}/>
                                }
                            </Space>
                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            {hasSelected ?
                                <Popconfirm
                                    title="确认删除?"
                                    onConfirm={this.delSel.bind(this)}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button type="primary" danger>删除</Button>
                                </Popconfirm>
                                : null}
                            <Input value={this.state.newcondition.keywords} style={{width: 250}}
                                   onChange={this.onchange.bind(this, 'keywords')} placeholder={'请输入关键字'}/>
                            {store.getState().data.info.type == 1 ?
                                <Select
                                    className='select'
                                    style={{width: 100}}
                                    placeholder="添加人"
                                    value={this.state.newcondition.aid ? this.state.newcondition.aid : null}
                                    onChange={this.onSelChange.bind(this, 'aid')}
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {this.arrsel(this.state.user_react)}
                                </Select>
                                : null
                            }
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'setime')}
                                         value={[(this.state.newcondition.starttime ? moment(this.state.newcondition.starttime) : null), (this.state.newcondition.endtime ? moment(this.state.newcondition.endtime) : null)]}/>
                            <Select className='select' style={{width: 100}} placeholder="状态"
                                    value={this.state.newcondition.status ? this.state.newcondition.status : null}
                                    onChange={this.onSelChange.bind(this, 'status')} allowClear>
                                <Option value={1}>已读</Option>
                                <Option value={2}>未读</Option>
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" onClick={this.reset.bind(this)}>重置</Button>
                            <Button type="primary" onClick={() => this.download()}>导出</Button>
                        </Space>
                        <Space>
                            {store.getState().data.info.type == 1 ?
                                <Add2 refresh={this.getdata.bind(this)}/>
                                : null}
                        </Space>
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    className='table'
                    // scroll={{x:2200}}
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
